import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'

const ChapterPage = ({data}) => {
  return (
    <Layout>
      <h1>{data.wpChapter.acfchapter.chapterTitle}</h1>
      <h2>{data.wpChapter.acfchapter.book.acfbook.bookTitle}</h2>
    </Layout>
  )
}

export default ChapterPage


export const query = graphql`
query($slug: String!) {
    wpChapter(slug: {eq: $slug}) {
      acfchapter {
        book {
          ... on WpBook {
            slug
            acfbook {
              bookTitle
            }
          }
        }
        chapterTitle
      }
    }
  }
`